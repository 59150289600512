<template>
  <div class="lesson-card" :class="statusClass">
    <div class="info">
      <div class="title">Занятие {{ number }}</div>
      <div class="options" v-if="status !== 0 && false">
        <div class="options-item">
          <i class="options-item__icon icon nicon-pencil"></i>
          <div class="options-item__content">
            <div class="options-item__title">Домашнее задание</div>
            <div class="options-item__value">Выдано</div>
          </div>
        </div>
      </div>
    </div>
    <div class="meta">
      <ClientOnly><div class="datetime">{{ datetimeText }}</div></ClientOnly>
      <button class="action-btn" v-if="status !== 0" @click="actionFunc">{{ actionText }} <i class="icon nicon-external-link"></i></button>
    </div>
  </div>
</template>

<script setup>
import { formatDate, formatTime } from '~/utils/date'

const props = defineProps({
  book: {
    type: Object,
    required: true
  },
  number: {
    type: Number,
    required: true
  }
})

const router = useRouter()
const route = useRoute()

const status = computed(() => {
  if (props.book.datetime_end <= new Date()) return 2
  if (props.book.datetime_start <= new Date()) return 1
  return 0
})

const statusClass = computed(() => ['upcoming', 'active', 'finished'][status.value])
const datetimeText = computed(() => [
  `Запланировано на ${formatDate(props.book.datetime_start)} в ${formatTime(props.book.datetime_start)}`,
  'В процессе',
  `Встреча прошла ${formatDate(props.book.datetime_start)} c ${formatTime(props.book.datetime_start)} до ${formatTime(props.book.datetime_end)}`
][status.value])
const actionText = computed(() => ['', 'Присоединиться', 'Смотреть запись'][status.value])

function actionFunc () {
  let linkData = ''
  if (status.value === 1) {
    linkData = router.resolve(route.fullPath + '/meet/' + props.book.id)
  }
  if (status.value === 2) {
    linkData = router.resolve(route.fullPath + '/record/' + props.book.id)
  }

  window.open(linkData.href, '_blank')
}
</script>

<style scoped lang="scss">
.lesson-card {
  padding: 32px 0;
  border-bottom: 1px solid var(--color-separator);
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  @media (max-width: 460px) {
    flex-direction: column;
    align-items: flex-start;
  }

  & > * {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  & .info {
    & .title {
      font-weight: 800;
      font-size: 24px;
      line-height: 28px;
      margin-bottom: 16px;
      margin-top: 7px;
      white-space: nowrap;
      @media (max-width: 460px) {
        font-size: 20px;
      }
    }
    & .options {
      display: flex;
      align-items: flex-start;
      &-item {
        display: flex;
        align-items: flex-start;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        &__icon {
          margin-top: 3px;
          margin-right: 13px;
          color: var(--color-elements-secondary);
        }
        &__value {
          font-size: 13px;
          text-transform: uppercase;
          color: var(--color-elements-secondary);
        }
      }
    }
  }
  & .meta {
    align-items: flex-end;
    gap: 20px;
    @media (max-width: 460px) {
      align-items: flex-start;
    }

    & .datetime {
      font-weight: 500;
      font-size: 13px;
      line-height: 24px;
      text-transform: uppercase;
      padding: 8px 10px;
      border-radius: 8px;
      @media (max-width: 460px) {
        padding: 0;
      }
    }
    & .action-btn {
      display: flex;
      align-items: center;
      font-weight: 800;
      font-size: 16px;
      line-height: 24px;
      text-transform: uppercase;
      color: var(--color-primary);
      white-space: nowrap;
      @media (max-width: 460px) {
        font-size: 14px;
        line-height: 20px;
      }
      & .icon {
        margin-left: 14px;
        @media (max-width: 460px) {
          margin-left: 10px;
        }
      }
    }
  }

  &.upcoming {
    & > * {
      justify-content: center;
    }
    & .title {
      margin-bottom: 0;
    }
    & .datetime {
      border: 2px solid var(--color-elements-quantery);
      @media (max-width: 460px) {
        padding: 8px 10px;
      }
    }
  }
  &.active {
    & .datetime {
      background: var(--color-primary);
      color: var(--color-bg);
    }
  }
}
</style>
