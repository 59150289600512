<template>
  <main>
    <div class="horiz-container default-grid" :class="{ pending }">

      <div class="content">
        <FancyBreadcrumbs :links="[
      { name: `Мои ${user.is_mentor ? 'менти' : 'менторы'}`, to: { name: 'mentor-my' } },
      { name: 'Занятия', to: { name: 'mentor-lessons', params: { id: related.id } } },
    ]" />
        <h1 class="page-title">Занятия</h1>
        <MentoringInfo class="mobile-info" v-if="related" v-bind="related" :sub="user.is_mentor ? 'Менти' : 'Ментор'" view-btn />
        <Tabs v-if="related" :tabs="tabs" />
        <div class="lessons-list" v-if="related">
          <MentoringLessonListCard
            v-for="(book, i) in related.books.sort((a, b) => b.datetime_start - a.datetime_start)"
            :key="book.id"
            :book="book"
            :number="related.books.length - i"
          />
        </div>
      </div>

      <div class="divider"></div>

      <div class="sidebar">
        <div class="sidebar__container">
          <template v-if="user.is_mentor">
            <div class="title">Менти</div>
            <MentiCard v-if="related" :menti="related" />
          </template>
          <template v-else>
            <div class="title">Ментор</div>
            <MentorCard v-if="related" :show-button="true" :mentor="related" />
          </template>
        </div>
      </div>
    </div>
  </main>
</template>

<script setup>
import FancyBreadcrumbs from '@/components/menu/FancyBreadcrumbs'
import MentorCard from '@/components/mentor/MentorCard'
import Tabs from '@/components/menu/Tabs'
import { getMentiProfile, getMyMentorProfile } from '@/api/mentoring'
import MentiCard from '@/components/menti/MentiCard'
import MentoringLessonListCard from '@/components/mentoring/MentoringLessonListCard'
import MentoringInfo from '@/components/mentor/MentoringInfo'

useHead({
  title: 'Занятия'
})

const router = useRouter()
const route = useRoute()
const user = inject('user')

const { data: related, pending } = await useAsyncData(() => (user.value.is_mentor ? getMentiProfile : getMyMentorProfile)(route.params.id))
if (!related.value) throw createForbiddenError()

const materialsCount = computed(() => related.value ? related.value[user.value.is_mentor ? 'materials' : 'lessons'].length : '')

const tabs = computed(() => [
  { name: 'Занятия', icon: 'list', current: true },
  {
    name: user.value.is_mentor ? 'Материалы для менти' : 'Все материалы',
    icon: 'file-text',
    ...(materialsCount.value > 0 ? { note: materialsCount.value } : {}),
    to: { name: 'mentor-materials', params: { id: related.value.id } }
  }
])
</script>

<style scoped lang="scss">
.content {
  grid-column: span 8;
  @media (max-width: 1310px) {
    grid-column: span 7;
  }
  @media (max-width: 920px) {
    grid-column: span 12;
  }
  & .page-title {
    margin-bottom: 32px;
  }
}

.divider {
  position: relative;
  @media (max-width: 920px) {
    display: none;
  }
  &::after {
    content: '';
    width: 1px;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    background: var(--color-separator);
  }
}

.sidebar {
  grid-column: span 3;
  @media (max-width: 1310px) {
    grid-column: span 4;
    width: 80%;
  }
  @media (max-width: 1200px) {
    width: 100%;
  }
  @media (max-width: 920px) {
    display: none;
  }
  & .title {
    font-weight: 900;
    font-size: 36px;
    line-height: 48px;
    text-transform: uppercase;
    margin-bottom: 18px;
  }
  &__container {
    position: sticky;
    top: 120px;
  }
}

.mobile-info {
  margin-bottom: 20px;
  display: none;
  @media (max-width: 920px) {
    display: block;
  }
}
</style>
